import React, {useEffect} from 'react';
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import ImageOfDay from '../components/Image-of-day.jsx';
import { auth } from '../utilities/firebase';
import { useNavigate } from 'react-router-dom';
import '../css/App.css';

function HomePage() {
    
    const navigate = useNavigate()

    useEffect(() => {
        if (auth.currentUser == null)
        {
            navigate('/')
        }

        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Header/>
            <ImageOfDay/>
            <Footer
                colour = "rgba(243,199,239,1)"
            />
        </div>
    );
}

export default HomePage;
