import React from 'react';
import { Link } from 'react-router-dom';
import '../css/header.css';
import { auth } from '../utilities/firebase'


function Header(props) {
    const handleLogout = async(e) => {
        try {
            const response = auth.signOut();
            console.log(response);
        } catch (error) {
            console.log('error logging out', error.message);
            alert("Error logging out...")
        }
    }
  
    return (
        <div className='header'>
            <header>
                <div class="container">
                    <Link to = '/home' style={{textDecoration: "none"}} className='heart-img'><img src= {require("../images/heart-icon.png")} alt="heart" /></Link>
                    <Link to = '/home' style={{textDecoration: "none"}}>
                        {auth.currentUser.uid === 'tbuWmzpeioh7uqaYIQeRtnKs4zE2' ? 
                        <h1>Joel<span style={{marginRight: "5px"}}><img src= {require("../images/heart-icon.png")} alt="heart" /></span><span style={{color: "#fa84d0"}}>Chloe</span></h1>
                    : <h1><span style={{color: "#78bfeb"}}>Joel</span><span style={{marginRight: "5px"}}><img src= {require("../images/heart-icon.png")} alt="heart" /></span>Chloe</h1>}
                    </Link>
                    <nav>
                        <ul>
                            <li><Link to = '/upload' className='header-links'> Upload </Link></li>
                            <li><Link to = '/gallery' className='header-links'> Gallery </Link></li>
                            <li><Link to = '/' className='header-links' onClick={handleLogout}> Log Out </Link></li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header;