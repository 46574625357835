import Snake from 'snake-game-react';

function SnakeGame() {
  return (
    <div className="App">
        <Snake 
            color1="#FF6EC7"
            color2="red"
            backgroundColor="rgb(1, 1, 59)"
        />  
    </div>
  );
}

export default SnakeGame;