import React, { useEffect, useState } from 'react'
import { getDownloadURL, listAll, ref} from 'firebase/storage'
import { storage } from '../utilities/firebase';
import ImageCard3 from './Image-card-3';
import '../css/img-gallery.css';
import '../css/time-machine-display.css';
import DigitPinwheel from './digit-pinwheel';

const TimeMachineDisplay = (props) =>
{
    const monthKey = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    var today = new Date();
    var year = today.getFullYear();

    const startYear = 2023;
    const [randYear, setRandYear] = useState(Math.floor(Math.random() * (year - startYear + 1)) + startYear)
    const [newImageClick, setNewImageClick] = useState(false);
    const [imagePath, setImagePath] = useState()
    const [imageURL, setImageURL] = useState([])
    const [isLEDLoading, setIsLEDLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        if (newImageClick) {
            setRandYear(Math.floor(Math.random() * (year - startYear + 1)) + startYear)
            setNewImageClick(false)
        }
        
        var monthListRef = ref(storage, 'images/' + randYear)
        listAll(monthListRef).then((response) => {
            const randMonth = Math.floor(Math.random() * (response.prefixes.length));
            var imageListRef = ref(storage, 'images/' + randYear + '/' + response.prefixes[randMonth].name + '/');
            listAll(imageListRef).then((response) => {
                const randDay = Math.floor(Math.random() * (response.items.length));
                setImagePath(response.items[randDay].fullPath.toString())
                getDownloadURL(response.items[randDay]).then((url) => setImageURL(url))
            })
        })

        setTimeout(function() {
            setIsLEDLoading(false);
        }, 5000);

        setTimeout(function() {
            setIsLoading(false);
        }, 6000);
    }, [newImageClick])

    useEffect(() => {
        window.scrollTo(0,0)

        setTimeout(function() {
            window.scroll({ 
                top: 300, 
                left: 0, 
                behavior: 'smooth' 
            });
        }, 1300);
    }, [])

    var pathArray;
    var date;
    if (imagePath !== undefined) {
        pathArray = imagePath.split("/")

        if (pathArray[3].length <= 2) {
            date = '0' + pathArray[3]
        }
    }

    function getNewImage(){
        setNewImageClick(true);
        setIsLEDLoading(true);
        setIsLoading(true);
    } 

    return <div className='gallery-container' style={{paddingTop: "110px"}}>
        <div className='title-annimation'>
            <div className='btf-title-container'>
                <h1 className='back-in-time'>
                    BACK<img src={require("../images/btf-arrow.png")} style={{width:"180px"}} alt="arrow" />
                </h1>
                <h1 className='in-time'>
                    <div>
                        IN
                    </div>
                    <div>
                        TIME
                    </div>
                </h1>
            </div>
        </div>
        <div className='inner-container'>
            <div className='clock-container metal linear'>
                <div className='clock-date'>
                    <div className='clock-day'>
                        <h5>DAY</h5>
                        {
                            date === undefined || isLEDLoading ?
                            <div className='clock-day-digits'>
                            <DigitPinwheel 
                                digit = '0'
                            />
                            <DigitPinwheel 
                                digit = '0'
                            />
                            </div>
                        :
                            <div className='clock-day-digits'>
                                <DigitPinwheel 
                                    digit = {date[0]}
                                />
                                <DigitPinwheel 
                                    digit = {date[1]}
                                />
                            </div>
                        }
                    </div>
                    <div className='clock-month'>
                        <h5>MONTH</h5>
                        {
                            pathArray === undefined || isLEDLoading ?
                            <div className='clock-month-digits'>
                            <DigitPinwheel 
                                digit = '0'
                            />
                            <DigitPinwheel 
                                digit = '0'
                            />
                            <DigitPinwheel 
                                digit = '0'
                            />
                        </div>
                        :
                        <div className='clock-month-digits'>
                            <DigitPinwheel 
                                digit = {monthKey[pathArray[2] - 1][0]}
                            />
                            <DigitPinwheel 
                                digit = {monthKey[pathArray[2] - 1][1]}
                            />
                            <DigitPinwheel 
                                digit = {monthKey[pathArray[2] - 1][2]}
                            />
                        </div>
                        }
                    </div>
                    <div className='clock-year'>
                        <h5>YEAR</h5>
                        {
                            pathArray === undefined || isLEDLoading ?
                            <div className='clock-year-digits'>
                                <DigitPinwheel 
                                    digit = '0'
                                />
                                <DigitPinwheel 
                                    digit = '0'
                                />
                                <DigitPinwheel 
                                    digit = '0'
                                />
                                <DigitPinwheel 
                                    digit = '0'
                                /> 
                            </div>
                            :
                            <div className='clock-year-digits'>
                                <DigitPinwheel 
                                    digit = {pathArray[1][0]}
                                />
                                <DigitPinwheel 
                                    digit = {pathArray[1][1]}
                                />
                                <DigitPinwheel 
                                    digit = {pathArray[1][2]}
                                />
                                <DigitPinwheel 
                                    digit = {pathArray[1][3]}
                                /> 
                            </div>
                        }
                    </div>
                    {
                        isLEDLoading ?
                        <div className='led-container'>
                            <div className='led-red'></div>
                            <div className='led-green-off'></div>
                        </div>
                        :
                        <div className='led-container'>
                            <div className='led-red-off'></div>
                            <div className='led-green'></div>
                        </div>
                    }
                </div>
                <div className='button-container'>
                    <button onClick={getNewImage}></button>
                </div>
                <h6>RESET</h6>
                <h3>DESTINATION TIME</h3>
            </div>
            {
                isLoading ?
                <div>
                    <img src={require("../images/delorean_gif.gif")} style={{width:"400px", margin:"auto", marginLeft:"50px"}} alt="delorean" />
                </div>
                :
                <div className='time-machine-img'>
                    <ImageCard3 
                        image = {imageURL}
                        imgPath = {imagePath}
                    />
                </div>     
            }  
        </div>
    </div>
}

export default TimeMachineDisplay;
