import React, {useEffect} from 'react';
import Header from '../components/Header.jsx'
import Footer2 from '../components/Footerv2.jsx';
import '../css/App.css';
import TimeMachineDisplay from '../components/Time-machine-display.jsx';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utilities/firebase';


function TimeMachine() {

    const navigate = useNavigate()

    useEffect(() => {
        if (auth.currentUser == null)
        {
            navigate('/')
        }
    }, [])

    return (
        <div>
            <Header/>
            <TimeMachineDisplay/>
            <Footer2
                colour = "#78bfeb"
            />
        </div>
    );
}

export default TimeMachine;
