import React, { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import {getDownloadURL, listAll, ref} from 'firebase/storage'
import { storage } from '../utilities/firebase';
import ImageCard2 from './Image-card-2';
import '../css/img-of-day.css';
import '../css/img-card-2.css'

const FullImageDisplay = (props) =>
{
    const monthKey = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    const { year, month } = useParams();
    const [imageDetails, setImageDetails] = useState([])

    let monthIdx = monthKey.indexOf(month.toString().toUpperCase()) + 1

    useEffect(() => {
        var imageListRef = ref(storage, 'images/' + year + '/' + monthIdx + '/')

        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                    getDownloadURL(item).then((url) => {     
                        
                        var haystack = JSON.stringify(imageDetails);
                        var needle = JSON.stringify([Number(item.name.substring(0, item.name.length - 1)), url])
                        
                        var idx = haystack.indexOf(needle);

                        if (idx === -1) setImageDetails( (prevState) => [...prevState, [Number(item.name.substring(0, item.name.length - 1)), url]]);
                        else console.log("image present");
                    })
                }
            )
        })
    }, [])

    imageDetails.sort((a, b) => a[0] - b[0])

    var shadowColour;

    return <div className='img-of-day-container-full-gallery'>
        <div className='inner-container-full-gallery'>
        <h1>What a wonderful month it was... <span style={{fontSize:"15px", marginLeft:"70px"}}>&#40;{month.toUpperCase()}/{year}&#41;</span></h1>
            {
                <div className="row-full-gallery">
                    {imageDetails.map( (img, id) => {

                        var lastChar = img[1].charAt(img[1].length - 1)
                        
                        if (lastChar === "J") shadowColour = "blue"
                        else if (lastChar === "C") shadowColour = "pink"

                        return (
                            <div className="column-full-gallery">
                                <ImageCard2 
                                    hover = {img[0]}
                                    image = {img[1]}
                                    imgShadow = {shadowColour}
                                />
                            </div>
                        )}
                    )}
                </div>
            }           
        </div>
    </div>
}

export default FullImageDisplay;
