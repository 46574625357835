// Card used for full gallery display (includes date hover with no adjacent year styling)

import React from 'react'
import '../css/img-card-2.css'

const ImageCard2 = (props) =>
{
    let day = props.hover

    return <div className='image-container-full-gallery'>
            <div className='column-container-full-gallery'> 
            <div className='img-hover'>{day}</div>
                {
                    props.imgShadow === 'blue' ? <img src={props.image} style={{border: "2px solid #78bfeb"}} alt="uploaded image" />
                    : <img src={props.image} style={{border: "2px solid #fa84d0"}} alt="uploaded image" />
                }
            </div>
    </div>
}

export default ImageCard2