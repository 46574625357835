import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/login-page.css'
import {signInAuthUserWithEmailAndPassword} from '../utilities/firebase'

function LoginPage() {

    const navigate = useNavigate();

    const [contact, setContact] = useState({
        email: '',
        password: ''
    })

    const {email, password} = contact;

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            const response = await signInAuthUserWithEmailAndPassword(email, password);
            console.log(response);
            navigate('/home');
        } catch (error) {
            console.log('error logging in', error.message);
            alert("Invalid username or password! Try again...")
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setContact ((preValue)=>{  
        return {
        ...preValue,
        [name]: value
        }})
    }

    return (
        <div className='login-container'>
            <div className='login-banner'>
                <img src= {require("../images/heart-icon.png")} alt="" />
            </div>
            <div className='login-details'>
                <div className='form-section'>
                    <h1>Joel+<span style={{paddingLeft: "4px"}}>Chloe</span></h1>
                    <form onSubmit>
                        <div id='email'>
                            <input type='email'name='email' placeholder='Email' onChange={handleChange} value={contact.email}></input>
                        </div>
                        <div id='password'>
                            <input type='password'name='password' placeholder='Password' onChange={handleChange} value={contact.password}></input>
                        </div>
                    </form>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <button type='submit' className='login-button' onClick={handleSubmit}>Login</button>
                    </div>
                    <div className='forgot-password'>
                        <Link to='/reset-password'><button className='sign-up'>Forgot Password</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default LoginPage;