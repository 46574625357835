import React, {useEffect} from 'react';
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import FullImageDisplay from '../components/Full-image-display.jsx';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utilities/firebase';

function FullImageGallery() {

    const navigate = useNavigate()

    useEffect(() => {
        if (auth.currentUser == null)
        {
            navigate('/')
        }

        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Header/>
            <FullImageDisplay />
            <Footer
                colour = "rgb(243, 199, 239)"
            />
        </div>
    );
}

export default FullImageGallery;
