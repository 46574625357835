import React from 'react';
import '../css/footer.css';

const Footer2 = (props) => {

    const colour = props.colour

    return (
        <div className='footer-container' style={{background: "linear-gradient(180deg, " + colour + " 0%, rgba(255,255,255,1) 70%)"}}>
            <div className='footer-text'>
                <div className='footer-heart'>
                    <h2>To Joel, From Chloe <img src= {require("../images/heart-icon.png")} alt="footer-heart"/></h2>
                </div>
                <h3>PS. I love you :&#41;</h3>
            </div>
        </div>
    )
}

export default Footer2;