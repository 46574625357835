import React, { useState, useEffect} from 'react'
import {getDownloadURL, listAll, ref} from 'firebase/storage'
import { storage } from '../utilities/firebase';
import ImageCard from './Image-card';
import '../css/img-of-day.css';
import '../css/img-card.css'

const ImageOfDay = (props) =>
{
    const [imageDetails, setImageDetails] = useState([])

    const startYear = 2023;

    var today = new Date();
    var day = String(today.getDate());
    var month = String(today.getMonth() + 1); 
    var year = today.getFullYear();

    const numberYears = year - startYear;
    var years = [];

    for (var i = 0; i <= numberYears; i++) {
        years.push((startYear+i));
    }

    useEffect(() => {
            for (const year of years) {

                var imageListRef = ref(storage, 'images/' + year + '/' + month + '/')

                listAll(imageListRef).then((response) => {
                    response.items.forEach((item) => {
                        if (item.name === day + 'C' || item.name === day + 'J'){
                            getDownloadURL(item).then((url) => {
                                
                                var haystack = JSON.stringify(imageDetails);
                                var needle = JSON.stringify([year, item.name, url])
                                
                                var idx = haystack.indexOf(needle);

                                if (idx === -1) setImageDetails( (prevState) => [...prevState, [year, item.name, url]]);
                                else console.log("image present");
                            })
                        }
                    })
                })
            }
    }, [])

    imageDetails.sort()

    var shadowColour;

    return <div className='img-of-day-container'>
        <div className='inner-container'>
        <h1>On this day in...</h1>
            {
                imageDetails.length === 0 ? 
                <h2 className='null-message'>Uh Oh! Looks like you will have to wait until next year :&#40;</h2>
                :
                <div className="row">
                    {imageDetails.map( (img, id) => {

                        var lastChar = img[1].charAt(img[1].length - 1)
                        
                        if (lastChar === "J") shadowColour = "blue"
                        else if (lastChar === "C") shadowColour = "pink"

                        return (
                            <div className="column">
                                {console.log(shadowColour)}
                                {
                                    id >= 1 && imageDetails[id - 1][0] === img[0] ?
                                    <ImageCard
                                    yearStyle = {{paddingTop: "24px"}}
                                    lineStyle = {{height: "0"}}
                                    image1 = {img[2]}
                                    imgShadow = {shadowColour}
                                    />
                                :
                                    <ImageCard
                                        year = {img[0]}
                                        image1 = {img[2]}
                                        imgShadow = {shadowColour}
                                    />
                                }
                            </div>
                        )}
                    )}
                </div>
            }           
        </div>
    </div>
}

export default ImageOfDay;
