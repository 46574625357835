import React from 'react'
import { Link } from 'react-router-dom';
import '../css/img-gallery.css';

const Gallery = (props) =>
{
    const startYear = 2023;

    var today = new Date();
    var year = today.getFullYear();

    const numberYears = year - startYear;
    var years = [];

    for (var i = 0; i <= numberYears; i++) {
        years.push((startYear+i));
    }

    return <div className='gallery-container'>
        <div className='inner-container'>
            <h1>Throughout the years we've spent together...</h1>  
                <div className='year-row'>
                    {years.map( (year) => {
                        return (
                            <div className="year-column">
                                <Link to={{ pathname: `/gallery/${year}`, state: year }}
                                className={`year-text`}>
                                    {year}
                                 </Link>
                            </div>
                        )}
                    )}
                </div>     
            <h1 className='last-header'>...And the many more to come</h1>
        </div>
    </div>
}

export default Gallery;
