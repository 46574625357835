import React, { useEffect, useState } from 'react'
import { listAll, ref} from 'firebase/storage'
import { storage } from '../utilities/firebase';
import { Link, useParams } from 'react-router-dom';
import '../css/img-gallery.css';

const MonthList = (props) =>
{
    const monthKey = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    const [months, setMonths] = useState([])

    var monthsNum = [];
    const { year } = useParams();
    var monthListRef = ref(storage, 'images/' + year)

    function compareNumbers(a, b) {
        return a - b;
    }

    useEffect(() => {
        listAll(monthListRef).then((response) => {
            response.prefixes.forEach((item) => {
                monthsNum.push(parseInt(item.name, 10))
            })

            monthsNum.sort(compareNumbers);

            for (const month in monthsNum) {
                if (month != null) {
                    var haystack = JSON.stringify(months);
                    var needle = JSON.stringify([monthKey[monthsNum[month] - 1]])
                    
                    var idx = haystack.indexOf(needle);

                    if (idx === -1) setMonths( (prevState) => [...prevState, [monthKey[monthsNum[month] - 1]]]);
                    else console.log("image present");
                }
            }
        })
    }, [])

    return <div className='gallery-container'>
        <div className='inner-container'>
            <h1>Select a month to remember...</h1>  
            <div className='year-row'>
                {months.map( (month) => {
                    return (
                        <div className="year-column">
                            <Link to={{ pathname: `/gallery/${year}/${month.toString().toLowerCase()}`, state: month }}
                            className={`year-text`}>
                                {month}
                            </Link>
                        </div>
                    )}
                )}
            </div>     
        </div>
    </div>
}

export default MonthList;