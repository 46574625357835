import React, { useState } from 'react';
import {storage, auth} from '../utilities/firebase'
import {ref, uploadBytes} from 'firebase/storage'
import SnakeGame from '../components/Snake-game.jsx';
import '../css/img-upload.css';

function ImgUpload() {
    const [isLoading, setIsLoading] = useState(false);
    const [imageUpload, setImageUpload] = useState(null)

    var user
    auth.currentUser.uid === 'tbuWmzpeioh7uqaYIQeRtnKs4zE2' ? user = 'C' : user = 'J'

    const uploadImage = (e) => {
        e.preventDefault()
        setIsLoading(true);
        if (imageUpload == null) {
            setIsLoading(false)
            return
        }

        for (const image of imageUpload) { 
            try {
                const imageRef = ref(storage, 'images/' 
                + image.lastModifiedDate.getFullYear() 
                + '/' + (image.lastModifiedDate.getMonth() + 1) 
                + '/' + image.lastModifiedDate.getDate() 
                + user);

                uploadBytes(imageRef, image).then(() => {
                    setIsLoading(false)
                    console.log("Image uploaded successfully!")
                })
            } catch (error) {
                setIsLoading(false)
                alert("Image failed to upload.")
            }
        }
    }

    return (
        <div className='upload-container' style={isLoading ? {backgroundColor: "rgb(1, 1, 59)"} : {backgroundColor: "rgb(225, 225, 225)"}}>
            {isLoading ? <SnakeGame /> : 
                <div className="post">
                    <div className='title-container title'>
                        <h1>Upload images:</h1>
                    </div>
                    <div className='title-container' >
                        <input type="file" multiple="multiple" accept="image/*" onChange={(e) => {setImageUpload(e.target.files)}}></input>
                        <button className="file-upload" onClick={uploadImage}>Upload</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default ImgUpload;