import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';


const Footer = (props) => {

    const colour = props.colour

    return (
        <div className='footer-container' style={{background: "linear-gradient(180deg, " + colour + " 0%, rgba(255,255,255,1) 70%)"}}>
            <div className='footer-text'>
                <div className='footer-heart'>
                    <h2>To Joel, From Chloe <img src= {require("../images/heart-icon.png")} alt="footer-heart"/></h2>
                </div>
                <Link to="/time-machine" style={{textDecoration: 'none'}}><h3>PS. Want to go back in time?</h3></Link>
            </div>
        </div>
    )
}

export default Footer;