import React from 'react'
import '../css/digit-pinwheel.css';

const DigitPinwheel = (props) =>
{
    return <div className='pinwheel-container'>
        <div className='pinwheel-shadow'>8</div>
        <div className='pinwheel-digit'>{props.digit}</div>
    </div>
}

export default DigitPinwheel