import React, {useEffect} from 'react';
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import '../css/App.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utilities/firebase';
import MonthList from '../components/Month-list.jsx';


function MonthGallery() {

    const navigate = useNavigate()

    useEffect(() => {
        if (auth.currentUser == null)
        {
            navigate('/')
        }

        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div>
            <Header/>
            <MonthList/>
            <Footer
                colour = "#78bfeb"
            />
        </div>
    );
}

export default MonthGallery;