import './css/App.css';
import React from 'react';
import HomePage from './routes/home-page'
import Upload from './routes/upload'
import LoginPage from './routes/login'
import CreateAccount from './routes/create-account';
import ResetPassword from './routes/forget-password';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import YearGallery from './routes/year-gallery';
import MonthGallery from './routes/month-gallery';
import FullImageGallery from './routes/full-image-gallery';
import TimeMachine from './routes/time-machine';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/gallery" element={<YearGallery />} />
          <Route path="/gallery/:year" element={<MonthGallery />} />
          <Route path="/gallery/:year/:month" element={<FullImageGallery />} />
          <Route path="/time-machine" element={<TimeMachine />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
