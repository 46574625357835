// Card used for time machine display (no date hover and no adjacent year styling)

import React from 'react'
import '../css/img-card-3.css'

const ImageCard3 = (props) =>
{
    let name = props.imgPath

    var shadowColour;
    var lastChar = "Z"
    
    if (name != undefined) lastChar = name.charAt(name.length - 1);                 
    if (lastChar === "J") shadowColour = "blue"
    else if (lastChar === "C") shadowColour = "pink"

    return <div className='time-machine-img-container'>
        {
            shadowColour === 'blue' ? <img src={props.image} style={{border: "4px solid #78bfeb"}} alt="uploaded image" />
            : <img src={props.image} style={{border: "4px solid #fa84d0"}} alt="uploaded image" />
        }
    </div>
}

export default ImageCard3