// Card used for img of day display (no date hover but has adjacent year styling)

import React from 'react'
import '../css/img-card.css'

const ImageCard = (props) =>
{
    return <div className='image-container'>
        <div className='column-container'> 
            <div className='year-styling'>
                <h2 style={props.yearStyle}>{props.year}</h2>
                <hr style={props.lineStyle}></hr>
            </div>
            {
                props.imgShadow === 'blue' ? <img src={props.image1} style={{border: "3px solid #78bfeb"}} alt="uploaded image" />
                : <img src={props.image1} style={{border: "3px solid #fa84d0"}} alt="uploaded image" />
            }
        </div>
    </div>
}

export default ImageCard